import React from "react";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";


export default function DemoPage() {
  return (
    <>
      <TitleAndMetaTags
        title="IT Help Desk AI Chatbot Demo by Workativ | Register for Workativ Assistant Webinar Here"
        description="Join our webinar on how to build Conversational AI Chatbot. Learn how to automate IT support with AI Chatbot with process automation."
        keywords={["workativ terms and conditions"]}
        ogTitle="IT Help Desk AI Chatbot Demo by Workativ | Register for Workativ Assistant Webinar Here"
        ogDescription="Join our webinar on how to build Conversational AI Chatbot. Learn how to automate IT support with AI Chatbot with process automation."
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <DemoPageContentAndForm formName={"DemoForm"}>
            <DemoPageContentAndForm.Header>
            See how 80% of repetitive IT support issues can be automated for your employees faster, instantly, and at fraction of costs using IT Helpdesk Chatbot. Register now! Limited seats.
            </DemoPageContentAndForm.Header>
            <DemoPageContentAndForm.Content>
            According to Robert Half Technology, If you are a business with 500 employees, then your employees have just lost 168,000 hours on productivity on account of needing IT support. That’s a lot! Thankfully you can fix it!
            </DemoPageContentAndForm.Content>
            <DemoPageContentAndForm.H6>
            The session will cover:


            </DemoPageContentAndForm.H6>
            <DemoPageContentAndForm.Ul>
              {liContent.map((li) => (
                <DemoPageContentAndForm.Li>
                  {li.content}
                </DemoPageContentAndForm.Li>
              ))}
            </DemoPageContentAndForm.Ul>
          </DemoPageContentAndForm>
         
        </Layout>
      </Container>
    </>
  );
}

const liContent = [
  {
    content: "Learn how end-users can self-resolve IT issues.",
  },
  {
    content:
      "Automate manual processes to deflect tickets away from IT Helpdesk ",
  },
  {
    content:
      "Use AI-led conversational self-service for instant and autonomous support from Slack, MS Teams etc.",
  },
  {
    content:
      "Connect Chatbot with 70+ apps, 600+ app workflow actions instantly.",
  },
  {
    content:
    "Bonus – Convert your MS Teams into Agent Channel and save costs on live chat software.",
  },
];

DemoPageContentAndForm.Header = ({ children }) => {
  return (
    <>
      <h1 className="webinar_header-bottom new-font-webinar">{children}</h1>
    </>
  );
};
DemoPageContentAndForm.Content = ({ children }) => {
  return <p>{children}</p>;
};
DemoPageContentAndForm.H6 = ({ children }) => {
  return <h6>{children}</h6>;
};
DemoPageContentAndForm.Ul = ({ children }) => {
  return <ul>{children}</ul>;
};
DemoPageContentAndForm.Li = ({ children }) => {
  return <li>{children}</li>;
};

DemoPageContentAndForm.Form = ({ children }) => {
  return <>{children}</>;
};

export function DemoPageContentAndForm({ children, formName }) {
  return (
    <section className="skit_contactus sign_up-form_us demo_page webinar_forms_head">
      <div className="container signup_form">
        <div className="row beta_change_form">
          <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 ">
            <h1 class="webinar_head">Live Demo</h1>
            {children}
            <p className="mb_0_webianr">
              <b>Can't make it?</b> Register anyway so we can send you recording in case you miss it.
            </p>
            <div className="webinar_footer_img">
              <h6>Presented By</h6>
              <div className="webinar_left_div">
              <div className="webinar_form_head_photo">
                <img
                  src={require("../../../assets/images/im.png")}
                  alt="webinar-ic"
                />
              </div>
              <div className="webinar_form_head_photo_info">
                <div className="webinar_name">Imran</div>
                <div className="webinar_title">Founder & CEO</div>
              </div>
              </div>
              <div className="webinar_right_div">
              <div className="webinar_form_head_photo">
                <img
                  src={require("../../../assets/images/vi.png")}
                  alt="webinar-ic"
                />
              </div>
              <div className="webinar_form_head_photo_info">
                <div className="webinar_name">Vinod </div>
                <div className="webinar_title">Product Manager</div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 demo_page_right">
            {formName === "DemoForm" ? <DemoForm /> : null}
          </div>
        </div>
      </div>
    </section>
  );
}

function DemoForm() {
  return (
    <div class="webinar_form ">
      <div class="iframe_zoho">
        <iframe
          src="https://us02web.zoom.us/webinar/register/WN_5ryR-vdgQZy9YpEeUNJJag"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
}
